const newline = '%0D%0A'

class Email {
  constructor(subject, body, userData) {
    this.subject = subject
    this.body = body
    this.userData = userData
  }

  getHref() {
    return `mailto:help@kit.com?subject=${this.subject}&body=${this._getBody()}`
  }

  _getBody() {
    return `${newline}${newline}${newline} 
            ----- Please add your message to the support team above this line -----
            ${newline}
            ${this.body.replaceAll('\n', newline)}
            ${newline}${newline}
            ${Object.entries(this.userData)
              .map(pair => pair.join(': '))
              .join(newline)}`
  }
}

export { Email as default }
